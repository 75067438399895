<template>
<div>
  <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" /> 

  <div class="content">
    <validation-observer ref="VFormKajianMandiriSC">
      <b-form @submit.prevent="doSubmit()">
        <div class="card">
          <div class="card-header bg-white">
            <div class="row align-items-center">
              <div class="col-md-auto">
                <div class="row align-items-center">
                  <div class="col-md-auto">
                    <h5 class="card-title font-weight-semibold">Kajian Mandiri SC</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-header">
            <div class="row">
              <div class="col-md-12">
                <table class="table table-sm  table-bordered mb-2">
                  <tbody>
                    <tr>
                      <th style="width:10%;" class="bg-primary">No </th>
                      <th style="width:40%;" class="bg-primary"> Indikator Proses </th>
                      <th class="bg-primary"> </th>
                    </tr>

                    <tr v-for="(v, k) in row.indikator_proses" :key="`ip-${k}`">
                      <td>{{ k + 1 }} </td>
                      <td>
                        <p class="mb-0">{{ v.label }}</p>
                        
                        <template v-if="v.additional_label">
                          <b-form-radio-group :options="v.options" v-model="v.option_value" @change="updateRow(row)" class="mt-1 mb-1">
                          </b-form-radio-group>
                          <p class="mb-0">{{ v.additional_label }}</p>
                        </template>
                      </td>
                      <td>
                        <div>
                          <b-form-radio-group :options="Config.mr.yesNoOptV4" v-model="v.value" @change="updateRow(row)">
                          </b-form-radio-group>
                        </div>
                      </td>
                    </tr>

                    <template>
                      <tr>
                        <td class="table-info">{{ (row.indikator_proses||[]).length + 1 }}</td>
                        <td class="table-info" colspan="2"><strong>Asesmen persalinan pasien menggunakan partograf ditulis
                            lengkap sebelum SC</strong></td>

                      </tr>
                      <tr v-for="(v, k) in row.asasemen_persalinan" :key="`ap-${k}`">
                        <td>{{ getAlphabetFromIndex(k) }}.</td>
                        <td>{{ v.label }}</td>
                        <td>
                          <div>
                            <b-form-radio-group :options="Config.mr.yesNoOptV4" v-model="v.value" @change="updateRow(row)">
                            </b-form-radio-group>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td class="table-info">17</td>
                        <td class="table-info" colspan="2"><strong>Pasien dilakukan dengan indikasi SC (pilih):</strong>
                        </td>

                      </tr>

                      <tr v-for="(v, k) in row.indikasi_sc" :key="`isc-${k}`">
                        <td>{{ getAlphabetFromIndex(k) }}).</td>
                        <td>
                          {{ v.label }} <br>

                          <b-form-input v-model="v.other_value" v-if="v.slug == 'OTHER'" @change="updateRow(row)"></b-form-input>
                        </td>
                        <td>
                          <div>
                            <b-form-radio-group :options="Config.mr.yesNoOptV4" v-model="v.value" @change="updateRow(row)">
                            </b-form-radio-group>
                          </div>
                        </td>
                      </tr>
                    </template>

                  </tbody>

                </table>
                <table class="table table-sm  table-bordered">
                  <tbody>
                    <tr>
                      <th style="width:10%;" class="bg-primary">No </th>
                      <th style="width:40%;" class="bg-primary">Indikator Luaran </th>
                      <th class="bg-primary"> </th>
                    </tr>

                    <tr v-for="(v, k) in row.indikator_luaran" :key="`il-${k}`">
                      <td>{{ k + 1 }}</td>
                      <td>{{ v.label }} </td>
                      <td>
                        <div>
                          <b-form-radio-group :options="Config.mr.yesNoOptV4" v-model="v.value" @change="updateRow(row)">
                          </b-form-radio-group>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table class="table table-sm  table-bordered mb-2 mt-2 text-center">
                  <tbody>
                    <tr>
                      <td style="width:50%;">
                        <p style="margin:0;"><strong>Nama</strong></p>
                        <b-form-input type="text" placeholder="" v-model="row.name" maxlength="128" @change="updateRow(row)" />
                      </td>
                      <td>
                        <p style="margin:0;"><strong>Tanggal Pengisian</strong></p>
                        <datepicker input-class="form-control transparent" placeholder="Tanggal" class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="row.date" @input="updateRow(row)">
                        </datepicker>
                      </td>
                      <!--
                      <td>

                        <div class="form-group">
                          <label for="">Staf Penerima <span class="text-danger">*</span></label>
                          <b-form-input placeholder="Nama Staf" v-model="row.staff_name" />
                          <VValidate
                            name="Nama"
                            v-model="row.staff_name"
                            :rules="mrValidation.staff_name"
                          />

                          <div class="signing-element mt-1" data-target-id="signature">
                            <VueSignaturePad
                              class="sign-container"
                              width="100%"
                              height="160px"
                              ref="signature"
                              @onend="onEnd('signature')"
                            />

                            <VValidate
                              name="Tanda Tangan"
                              v-model="row.signature"
                              :rules="mrValidation.signature"
                            />

                            <div class="mt-2">
                              <button type="button" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner" @click="undoTTD('signature','signature')">
                                <b><i class="icon-cross3"></i></b>
                                <span>Clear</span>
                              </button>
                              <button type="button" @click="output('signature','signature')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                                  <b><i class="icon-checkmark2"></i></b>
                                <span>Simpan</span>
                              </button>
                            </div>                 
                          </div>

                          
                        </div>
                      </td>
                      -->
                    </tr>

                  </tbody>
                </table>

              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="text-right">
              <button type="button" class="btn" @click="$router.back()">Kembali</button>
              <button type="button" class="btn btn-outline-primary mr-2" @click="$router.back()">Simpan Sementara</button>
              <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
            </div>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</div>
</template>

<script>
import PatientInfo from '@/components/Ranap/PatientInfo.vue'
import Datepicker from 'vuejs-datepicker'

import MonitoringOptions from '@/libs/MonitoringOptions.js'
import Formatter from '@/libs/Formatter.js'

import Gen from '@/libs/Gen'

import GlobalVue from '@/libs/Global'

export default {
  components: {
    PatientInfo, Datepicker
  },

  extends: GlobalVue,

  data() {
    return {
      loading: {
        patientInfo: false
      },

      patientData: {},

      mrCriteria: [],
      mrDischargePlanning: [],
      dataLaporan: [{}],
      mrValidation: {},

      row: {},

      formData: {
        discharge_planning: [],
        is_discharge: false
      },
    }
  },

  mounted() {
    this.getPatientInfo()
    this.getDataForm()    
  },

  methods: {
    getAlphabetFromIndex(index) {
      return String.fromCharCode(index + 97)
    },

    setSignature(signature){
      setTimeout(() => { 
        if(signature) this.$refs['signature'].fromDataURL(signature)
      }, 1200)
    },    
    undoTTD(refs,field){
      this.$refs[refs].clearSignature()
      this.row[field] = null
    },
    output(refs,field) {
      let { isEmpty, data } = this.$refs[refs].saveSignature()
      if(!isEmpty){
        this.row[field] = data
      }

      this.updateRow(this.row)
    },

    addLaporan(){
      this.dataLaporan.push({})
      this.updateRow(this.dataLaporan[this.dataLaporan.length - 1])
    },

    removeLaporan(key){
      this.dataLaporan.splice(key, 1)
    },
    updateRow(data){
      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            type: !data.id ? "add-kajian-mandiri-sc" : "update-kajian-mandiri-sc",
            id_registrasi: this.$route.params.pageSlug,            
            ...data
          }
        },
        "POST"
      ).then(resp => {
        this.$set(this.loading, 'patientInfo', false)
        if(!data.id){
          this.$set(data, 'id', resp.data.id)
        }
      })
    },
    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.params.pageSlug
          }
        },
        "POST"
      ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)

        this.$set(this.loading, 'patientInfo', false)
      })
    },

    getDataForm() {
      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: "get-form",
            id_registrasi: this.$route.params.pageSlug
          }
        },
      ).then(resp => {
        const {
          row,
          mrValidation
        } = resp.data

        this.$set(this, 'row', row)
        this.$set(this, 'mrValidation', mrValidation)

        this.setSignature(row.signature)
      })
    },

    doSubmit() {
      this.$refs['VFormKajianMandiriSC'].validate().then(success => {
        if (!success) return false
        this.$swal.fire({
          title: 'Loading...',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          }
        })

        setTimeout(() => { 
          this.$swal.close()

          this.$swal({
            title: "Berhasil mengisi laporan persalinan",
            icon: 'success',
            confirmButtonText: 'Ok'
          }).then(result => {
            if (result.value) {
              this.$router.back()
            }
          })
        }, 700)
      })
    }
  },

  watch: {
    $route() {
      this.getPatientInfo()
      this.getDataForm()
    },
  },

  computed: {
    Formatter(){
      return Formatter
    },

    MonitoringOptions(){
      return MonitoringOptions
    },
  }
}
</script>